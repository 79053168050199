/* You can add global styles to this file, and also import other style files */
* {
  box-sizing: border-box;
}
html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  direction: rtl;
  font-family: "Roboto", sans-serif;
  overflow-x: hidden;
}
h1, h2, h3, h4, h5, h6 {
  margin: 0;
}
.custom-btn {
  background: #917050;
  color: #fff;
  font-size: 20px;
  border-radius: 8px;
  padding: 11px 28px;
  cursor: pointer;
}
input[type=text] {
  height: 100%;
  width: 100%;
  color: #212121;
  border: 1px solid #212121;
  border-radius: 8px;
}